import React from "react";
import bunnyLogo from "assets/bunnyLogo.png";
import logo from "assets/logo.png";
// import { Counter } from "./features/counter/Counter";
import "./App.scss";
import { Typography } from "@material-ui/core";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={bunnyLogo} className="App-logo" alt="logo" />
        <br />
        <br />
        <Typography variant="h2" className="construction">
          Under construction{" "}
          <img src={logo} className="secondary-logo" alt="logo2" />
        </Typography>
      </header>
    </div>
  );
}

export default App;
